<template>
  <div class="card p-5 card-citmer cardRadius">
    <div class="row mb-4">
      <div class="text-end">
        <date-picker
          v-model="date"
          :format="'DD/MM/YYYY'"
          :input-class="'form-control inputsGeneralClass w-100'"
          placeholder="Seleccione un rango de fecha"
          @change="getData(null)"
          range />
      </div>
    </div>
    <b-pagination
      v-model="page"
      :total-rows="total"
      :per-page="limit"
      @change="getData($event)"
      align="right"
      aria-controls="my-table"></b-pagination>
    <b-table
      empty-text="No hay citas"
      show-empty
      :items="appointments"
      :fields="fieldsTable"
      :table-class="'patientsHeadClass'"
      responsive
      striped
      borderless>
      <template v-slot:cell(medic_initials)="row">
        <p
          class="mb-0 fw-bold"
          :title="row.item.medic_name"
          :style="`color: ${row.item.list_services[0].agenda_color} !important`">
          {{ row.item.medic_initials }}
        </p>
      </template>
      <template v-slot:cell(created_by_username)="row">
        <p class="mb-0" :title="row.item.created_by">
          {{ row.item.created_by_username }}
        </p>
      </template>
      <template v-slot:cell(status)="row">
        <p
          class="text-secondary w-auto"
          :class="{
            'underline-warning': row.item.status === 'untaken',
            'underline-success': row.item.status === 'taken',
            'underline-danger': row.item.status === 'cancelled',
            'underline-primary': row.item.status === 'accepted',
          }">
          {{ translateStatus(row.item.status) }}
        </p>
      </template>
      <template v-slot:cell(date_time)="row">
        <span>
          {{ convertNextDate(row.item) }}
        </span>
      </template>
      <!-- SERVICE -->
      <template v-slot:cell(service)="row">
        <div
          class="p-relative btn"
          :class="
            !hasManyServices(row.item.list_services.length) ? 'noPointer' : ''
          ">
          <template v-if="hasManyServices(row.item.list_services.length)">
            <span
              @click="openPreviewServices(row.item)"
              class="fw-bold"
              :class="['pointer']"
              :title="`Agenda: ${row.item.list_services[0].agenda_name}`"
              :style="`color: ${row.item.list_services[0].agenda_color} !important`">
              {{ row.item.service ? row.item.service : "Sin servicio" }}
            </span>
            <span
              @click="openPreviewServices(row.item)"
              class="servicesBadge pointer text-center"
              v-text="
                row.item.list_services.length < 100
                  ? row.item.list_services.length
                  : '99.'
              "></span>
          </template>
          <span
            v-else
            class="fw-bold"
            :title="`Agenda: ${row.item.list_services[0].agenda_name}`"
            :style="`color: ${row.item.list_services[0].agenda_color} !important`">
            {{ row.item.service ? row.item.service : "Sin servicio" }}
          </span>
        </div>
      </template>
      <!-- SERVICE / -->
      <template v-slot:cell(patient_type)="row">
        <font-awesome-icon
          class="tableSizeIconIdUser"
          :title="titleByType(row.item.patient_type)"
          :class="colorIconByType(row.item.patient_type)"
          :icon="['fa', 'hospital-user']" />
      </template>
      <!-- CONFIRMATION -->
      <template v-slot:cell(confirmation)="row">
        <span
          class="d-inline-flex align-items-center"
          :class="[
            {
              'pointer':
                row.item.confirmation === 'pending' ||
                row.item.confirmation === 'schedule' ||
                (row.item.confirmation === 'confirmed' &&
                  row.item.on_time &&
                  !includesAsesorString(row.item.list_services)),
              'not-allowed':
                row.item.confirmation === 'confirmed' &&
                (!row.item.on_time ||
                  includesAsesorString(row.item.list_services)),
            },
            confirmStatus[row.item.confirmation ? 0 : 1].class,
          ]">
          <font-awesome-icon
            class="me-3 tableSizeIcon"
            :icon="['far', 'calendar-check']" />
          <span class="mt-1">
            {{ validateConfirmation(row.item) }}
          </span>
        </span>
      </template>
      <!-- CONFIRMATION / -->
      <!-- ACTIONS -->
      <template v-slot:cell(actions)="row">
        <font-awesome-icon
          @click="showPaymentEvidence(row.item)"
          :disabled="row.item.is_paid !== 'paid_out'"
          :title="titleHoverPaymentIcon(row.item)"
          class="tableIcon"
          :class="{
            'text-secondary disabledClass': isDisabledIcon(row.item),
            'text-primary pointer': isAvailableIcon(row.item),
          }"
          :icon="iconActions(row.item)" />
      </template>
      <!-- ACTIONS / -->
    </b-table>
    <b-modal
      ref="paymentAppointmentModal"
      id="paymentAppointmentModal"
      hide-header
      hide-ok
      @ok="hideModal"
      :ok-title="'Cerrar'"
      @shown="updateAppointmentInfo"
      ok-variant="secondary"
      ok-only
      size="lg">
      <PaymentEvidence
        ref="paymentEvidence"
        :hasFooterButtons="false"
        :haSecondOpinion="haSecondOpinion"
        @reloadPatientList="getData"
        @resetComponent="$emit('resetComponent')" />
    </b-modal>
    <PreviewServicesVue :patient="appointment" />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HistoryAppointments",
  components: {
    DatePicker,
    PreviewServicesVue: () =>
      import("@/components/patient/PreviewServices.vue"),
    PaymentEvidence: () =>
      import("@/components/patient/filiation/modals/PaymentEvidence.vue"),
  },
  data() {
    return {
      date: [new Date(), new Date()],
      total: 0,
      page: 1,
      appointment: {},
      appointments: [],
      haSecondOpinion: false,
      limit: 10,
      confirmStatus: [
        {
          label: "Confirmada",
          class: "text-primary",
        },
        {
          label: "Pendiente",
          class: "text-secondary",
        },
      ],
      fields: [
        {
          class: "text-center text-primary",
          key: "medic_initials",
          label: "Responsable",
        },
        {
          class: "text-center",
          key: "created_by_username",
          label: "Agendado por",
        },
        {
          class: "text-center text-primary",
          key: "filiation_num",
          label: "Filiación",
        },
        { class: "text-center text-primary", key: "nec", label: "NEC" },
        {
          class: "text-center text-primary",
          key: "patient_type",
          label: "Tipo",
        },
        { class: "text-center", key: "patient_name", label: "Nombre" },
        {
          class: "text-center",
          key: "date_time",
          label: "Fecha de Cita",
        },
        { class: "text-center", key: "confirmation", label: "Asistencia" },
        { class: "text-center", key: "service", label: "Servicio" },
        {
          class: "text-center text-primary",
          key: "status",
          label: "Estado",
        },
        { class: "text-center", key: "actions", label: "Comprobantes" },
      ],
    };
  },
  watch: {
    getClinic() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.$store.commit("newModal", {
      key: "paymentAppointmentModal",
      ref: this.$refs.paymentAppointmentModal,
    });
  },
  computed: {
    ...mapGetters(["getUserType", "getClinic", "getModal"]),
    fieldsTable() {
      const permissionToIconProfs = [
        "cash_register",
        "manager",
        "abc",
        "manager_abc",
        "superadmin",
      ];
      if (permissionToIconProfs.includes(this.getUserType)) {
        return this.fields;
      }
      return this.fields.filter((field) => field.key !== "actions");
    },
  },
  methods: {
    ...mapActions(["updatePatientSelected"]),
    hasManyServices(list_services) {
      return list_services > 1;
    },
    openPreviewServices(appointment) {
      this.appointment = appointment;
      this.getModal("previewServices").show();
    },
    validateConfirmation(row) {
      const { confirmation, service, list_services } = row;
      const { agenda_name } = list_services[0];
      if (agenda_name === "ABC" && service === "Seguimiento a Px") {
        return "NA";
      }
      return this.confirmStatus[confirmation ? 0 : 1].label;
    },
    isDisabledIcon(appointment) {
      if (
        !this.isPaidAppointment(appointment) &&
        !this.isSecondOpinion(appointment)
      ) {
        return true;
      }
      return false;
    },
    isAvailableIcon(appointment) {
      if (this.isPaidAppointment(appointment)) {
        return true;
      }
      if (this.isSecondOpinion(appointment)) {
        return true;
      }
      return false;
    },
    isSecondOpinion(appointment) {
      return (
        (appointment.is_paid === "free_of_charge" ||
          appointment.second_opinion_paid) &&
        appointment.second_opinion_doc.length > 0
      );
    },
    isPaidAppointment(appointment) {
      return (
        (appointment.is_paid === "paid_out" ||
          appointment.is_paid === "not_payed") &&
        appointment.vouchers.length > 0
      );
    },
    iconActions(appointment) {
      if (
        this.isSecondOpinion(appointment) ||
        appointment.second_opinion_paid
      ) {
        return ["fa", "user-group"];
      }
      return ["fa", "money-bill"];
    },
    includesAsesorString(services) {
      let includesAsesorString = false;
      services.forEach((service) => {
        if (service.name.toLowerCase().includes("asesor")) {
          includesAsesorString = true;
        }
      });
      return includesAsesorString;
    },
    translateStatus(status) {
      const states = {
        untaken: "Pendiente",
        taken: "Completada",
        cancelled: "Cancelada",
        accepted: "Confirmada",
      };
      return states[status] || false;
    },
    updateAppointmentInfo() {
      this.$refs.paymentEvidence.getAppointmentInfo();
    },
    hideModal() {
      this.$store.getters.getModal("paymentAppointmentModal").hide();
    },
    showPaymentEvidence(appointment) {
      if (this.isDisabledIcon(appointment)) return;
      this.haSecondOpinion =
        appointment.has_second_opinion &&
        appointment.is_paid === "free_of_charge";
      this.updatePatientSelected(appointment);
      this.getModal("paymentAppointmentModal").show();
    },
    titleHoverPaymentIcon(row) {
      if (row.has_second_opinion || row.is_paid === "free_of_charge") {
        if (row.second_opinion_doc?.length > 0) {
          return "Si contiene archivo de segunda opinión";
        }
        return "No contiene archivo de segunda opinión";
      }
      if (row.is_paid === "paid_out") return "Si contiene comprobante de pago";
      return "No contiene comprobante de pago";
    },
    convertNextDate(info) {
      if (info.appointment_date) {
        return `${this.$moment(info.appointment_date).format("DD/MM/YYYY")} ${
          info.appointment_time
        }`;
      }
      return "No hay citas próximas";
    },
    colorIconByType(type) {
      const colors = {
        internal: "text-success",
        external: "text-primary",
        donor: "text-danger",
      };
      return colors[type];
    },
    titleByType(type) {
      const titles = {
        internal: "Paciente interno",
        external: "Paciente externo",
        donor: "Donante",
      };
      return titles[type];
    },
    colorStatus(item) {
      const states = {
        paid_out: "paidWaitForAuth",
        not_payed: "notPaidCitmer",
        free_of_charge: "secondOpinionCitmer",
      };
      if (item.cash_register_confirmation) {
        return "paidCitmer";
      }
      return states[item.is_paid] || false;
    },
    getData(page = null) {
      const payload = {
        clinic_id: this.getClinic,
        limit: this.limit,
        page: page || this.page,
      };
      [payload.startDate] = this.date;
      [, payload.endDate] = this.date;
      this.$api.appointments.history(payload).then((response) => {
        this.appointments = response.data.appointments;
        this.total = response.data.appointments_total;
      });
    },
  },
};
</script>

<style></style>
