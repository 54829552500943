var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card p-5 card-citmer cardRadius"},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"text-end"},[_c('date-picker',{attrs:{"format":'DD/MM/YYYY',"input-class":'form-control inputsGeneralClass w-100',"placeholder":"Seleccione un rango de fecha","range":""},on:{"change":function($event){return _vm.getData(null)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)]),_c('b-pagination',{attrs:{"total-rows":_vm.total,"per-page":_vm.limit,"align":"right","aria-controls":"my-table"},on:{"change":function($event){return _vm.getData($event)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('b-table',{attrs:{"empty-text":"No hay citas","show-empty":"","items":_vm.appointments,"fields":_vm.fieldsTable,"table-class":'patientsHeadClass',"responsive":"","striped":"","borderless":""},scopedSlots:_vm._u([{key:"cell(medic_initials)",fn:function(row){return [_c('p',{staticClass:"mb-0 fw-bold",style:(("color: " + (row.item.list_services[0].agenda_color) + " !important")),attrs:{"title":row.item.medic_name}},[_vm._v(" "+_vm._s(row.item.medic_initials)+" ")])]}},{key:"cell(created_by_username)",fn:function(row){return [_c('p',{staticClass:"mb-0",attrs:{"title":row.item.created_by}},[_vm._v(" "+_vm._s(row.item.created_by_username)+" ")])]}},{key:"cell(status)",fn:function(row){return [_c('p',{staticClass:"text-secondary w-auto",class:{
          'underline-warning': row.item.status === 'untaken',
          'underline-success': row.item.status === 'taken',
          'underline-danger': row.item.status === 'cancelled',
          'underline-primary': row.item.status === 'accepted',
        }},[_vm._v(" "+_vm._s(_vm.translateStatus(row.item.status))+" ")])]}},{key:"cell(date_time)",fn:function(row){return [_c('span',[_vm._v(" "+_vm._s(_vm.convertNextDate(row.item))+" ")])]}},{key:"cell(service)",fn:function(row){return [_c('div',{staticClass:"p-relative btn",class:!_vm.hasManyServices(row.item.list_services.length) ? 'noPointer' : ''},[(_vm.hasManyServices(row.item.list_services.length))?[_c('span',{staticClass:"fw-bold",class:['pointer'],style:(("color: " + (row.item.list_services[0].agenda_color) + " !important")),attrs:{"title":("Agenda: " + (row.item.list_services[0].agenda_name))},on:{"click":function($event){return _vm.openPreviewServices(row.item)}}},[_vm._v(" "+_vm._s(row.item.service ? row.item.service : "Sin servicio")+" ")]),_c('span',{staticClass:"servicesBadge pointer text-center",domProps:{"textContent":_vm._s(
              row.item.list_services.length < 100
                ? row.item.list_services.length
                : '99.'
            )},on:{"click":function($event){return _vm.openPreviewServices(row.item)}}})]:_c('span',{staticClass:"fw-bold",style:(("color: " + (row.item.list_services[0].agenda_color) + " !important")),attrs:{"title":("Agenda: " + (row.item.list_services[0].agenda_name))}},[_vm._v(" "+_vm._s(row.item.service ? row.item.service : "Sin servicio")+" ")])],2)]}},{key:"cell(patient_type)",fn:function(row){return [_c('font-awesome-icon',{staticClass:"tableSizeIconIdUser",class:_vm.colorIconByType(row.item.patient_type),attrs:{"title":_vm.titleByType(row.item.patient_type),"icon":['fa', 'hospital-user']}})]}},{key:"cell(confirmation)",fn:function(row){return [_c('span',{staticClass:"d-inline-flex align-items-center",class:[
          {
            'pointer':
              row.item.confirmation === 'pending' ||
              row.item.confirmation === 'schedule' ||
              (row.item.confirmation === 'confirmed' &&
                row.item.on_time &&
                !_vm.includesAsesorString(row.item.list_services)),
            'not-allowed':
              row.item.confirmation === 'confirmed' &&
              (!row.item.on_time ||
                _vm.includesAsesorString(row.item.list_services)),
          },
          _vm.confirmStatus[row.item.confirmation ? 0 : 1].class ]},[_c('font-awesome-icon',{staticClass:"me-3 tableSizeIcon",attrs:{"icon":['far', 'calendar-check']}}),_c('span',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.validateConfirmation(row.item))+" ")])],1)]}},{key:"cell(actions)",fn:function(row){return [_c('font-awesome-icon',{staticClass:"tableIcon",class:{
          'text-secondary disabledClass': _vm.isDisabledIcon(row.item),
          'text-primary pointer': _vm.isAvailableIcon(row.item),
        },attrs:{"disabled":row.item.is_paid !== 'paid_out',"title":_vm.titleHoverPaymentIcon(row.item),"icon":_vm.iconActions(row.item)},on:{"click":function($event){return _vm.showPaymentEvidence(row.item)}}})]}}])}),_c('b-modal',{ref:"paymentAppointmentModal",attrs:{"id":"paymentAppointmentModal","hide-header":"","hide-ok":"","ok-title":'Cerrar',"ok-variant":"secondary","ok-only":"","size":"lg"},on:{"ok":_vm.hideModal,"shown":_vm.updateAppointmentInfo}},[_c('PaymentEvidence',{ref:"paymentEvidence",attrs:{"hasFooterButtons":false,"haSecondOpinion":_vm.haSecondOpinion},on:{"reloadPatientList":_vm.getData,"resetComponent":function($event){return _vm.$emit('resetComponent')}}})],1),_c('PreviewServicesVue',{attrs:{"patient":_vm.appointment}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }